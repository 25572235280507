'use client';

import {
  Icon,
  IconArrowBackUp,
  IconArrowRight,
  IconArticle,
  IconBan,
  IconBrandTelegram,
  IconCalendarTime,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileDislike,
  IconFileImport,
  IconFileInvoice,
  IconFileUpload,
  IconFlag,
  IconLicense,
  IconMessage2,
  IconMessage2Question,
  IconPencilPlus,
  IconPlus,
  IconReportMoney,
  IconThumbDown,
  IconThumbUp,
  IconTournament,
  IconTransfer,
  IconTrophy,
  IconUserMinus,
  IconUserPlus,
  IconWriting,
  IconFilePlus,
  IconFileX,
  IconFileLike,
} from '@tabler/icons-react';
import { AuthInfoResponseBody, EventComplete, EventDetailUnion } from 'bff';
import { EventDetailObjects, EventType, User, UserType } from 'database';
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage, Badge, cn } from 'ui';
import { FileList, FileListItem, FileListLabel } from './File';
import { useTranslations } from 'i11n';
import { IconReport } from '@tabler/icons-react';
import { IconX, IconAlertCircle } from '@tabler/icons-react';
import { IconQuestionMark, IconBell } from '@tabler/icons-react';
import { IconSend } from '@tabler/icons-react';
import { format, isValid } from 'date-fns';
import { useSharedAuth } from '..';
import Link from 'next/link';
import { useMeasure } from '@uidotdev/usehooks';

const iconsByEvents: {
  [K in EventType]: Icon;
} = {
  pending_file_request_upload: IconBell,
  complaint_rejected: IconThumbDown,
  complaint_approved: IconThumbUp,
  complaint_authorized: IconCheck,
  external_complaint_selected: IconCheck,
  claim_updated: IconEdit,
  claim_estimates_updated: IconReportMoney,
  claim_ownership_assigned: IconTransfer,
  claim_ownership_transfer_rejected: IconBan,
  claim_ownership_transfer_accepted: IconCheck,
  claim_ownership_transfer_requested: IconBrandTelegram,
  contract_created: IconWriting,
  order_created: IconWriting,
  rfp_created: IconTournament,

  contract_invoice_uploaded: IconFileInvoice,
  contract_updates_requested: IconQuestionMark,
  contract_updates_published: IconArticle,

  claim_documentation_requested: IconQuestionMark,

  order_invoice_uploaded: IconFileInvoice,
  order_updates_requested: IconQuestionMark,
  order_updates_published: IconArticle,

  contract_report_rejected: IconX,
  contract_report_created: IconReport,
  contract_report_accepted: IconCheck,

  order_report_rejected: IconX,
  order_report_created: IconReport,
  order_report_accepted: IconCheck,

  appraisal_invoice_uploaded: IconFileInvoice,
  appraisal_updates_requested: IconQuestionMark,
  appraisal_updates_published: IconArticle,
  appraisal_report_rejected: IconX,
  appraisal_report_created: IconReport,
  appraisal_report_accepted: IconCheck,

  external_complaint_created: IconPencilPlus,

  pending_complaint_created: IconPlus,

  offer_expired: IconCalendarTime,
  offer_rejected: IconFileDislike,
  offer_accepted: IconFileLike,

  offer_agreement_expired: IconCalendarTime,
  offer_agreement_cancelled: IconFileDislike,
  offer_agreement_rejected: IconFileDislike,
  offer_agreement_completed: IconLicense,

  sinister_complaint_chat_message_send: IconMessage2,
  sinister_claim_chat_message_send: IconMessage2,
  fraud_news_requested: IconMessage2Question,
  fraud_news_answered: IconArticle,
  claim_form_step_completed: IconFlag,
  claim_form_step_view: IconEye,

  claim_derived: IconArrowRight,
  claim_responsibility_returned: IconArrowBackUp,

  claim_responsible_unassigned: IconUserMinus,
  claim_responsible_assigned: IconUserPlus,

  claim_collaborator_unassigned: IconUserMinus,
  claim_collaborator_assigned: IconUserPlus,

  complaint_collaborator_unassigned: IconUserMinus,
  complaint_collaborator_assigned: IconUserPlus,

  claim_created: IconLicense,
  complaint_created: IconLicense,
  file_requested: IconFileImport,
  file_request_uploaded: IconFileUpload,
  upload_files: IconFileUpload,
  claim_status_updated: IconFileUpload,
  complaint_status_updated: IconFileUpload,
  complaint_sinister_cause_updated: IconFileUpload,
  rfp_bid_submitted: IconSend,
  rfp_bid_winner_selected: IconTrophy,
  rfp_expired: IconX,

  alert_sla_objective_progress: IconAlertCircle,
  alert_sla_total_progress: IconAlertCircle,

  offer_created: IconFilePlus,
  offer_canceled: IconFileX,
};

interface ActivityContext {
  eventsCount: number;
  event: EventComplete;
  index: number;
}

const ActivityContext = React.createContext<ActivityContext | undefined>(
  undefined,
);

const useActivityContext = () => React.useContext(ActivityContext)!;

const EventDetailList = ({ children }: { children: React.ReactNode }) => {
  return <div className='gap-2 flex flex-row flex-wrap'>{children}</div>;
};

const MessageAuthor = ({ children }: { children: React.ReactNode }) => {
  return <p className='text-muted-foreground text-xs italic'>"{children}"</p>;
};

const EventDetailContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('w-full mt-2 space-y-2 text-base_brand', className)}>
      {children}
    </div>
  );
};

function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}

const parseSuperType = (value: any, fallback: () => string) => {
  if (value && isIsoDate(value)) return format(new Date(value), 'dd/MM/yyyy');

  if (typeof value === 'boolean') return value ? 'Si' : 'No';

  return fallback();
};

const EventDetail = () => {
  const { event } = useActivityContext();

  const auth = useSharedAuth();

  const t = useTranslations('claim.steps');

  const rootT = useTranslations();

  const isTriggeredByAuthUser = event.triggered_by?.id === auth.user?.id;

  switch (event.type) {
    case 'alert_sla_objective_progress':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>SLA: {event.metadata.sla_name}</Badge>
            <Badge variant='outline'>
              {rootT(
                `sla.types.${event.metadata.sla_objective_reference_type}`,
              )}
              : {event.metadata.sla_objective_reference_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'alert_sla_total_progress':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>SLA: {event.metadata.sla_name}</Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'complaint_estimates_updated':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Cobertura: {event.metadata.affected_coverage_name}
            </Badge>
            {event.metadata.operands.map((operand) => (
              <Badge variant='outline'>
                {operand.name}: {operand.value}
              </Badge>
            ))}
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_rejected':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>

            <Badge variant='outline'>Motivo: {event.metadata.reason}</Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_approved':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_authorized':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_created':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>

            <Badge variant='outline'>
              Póliza: {event.metadata.external_policy_number}
            </Badge>

            <Badge variant='outline'>
              Coberturas: {event.metadata.coverages.join(', ')}
            </Badge>
            <Badge variant='outline'>
              Causa del siniestro: {event.metadata.sinister_cause_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'rfp_expired':
      return (
        <EventDetailContainer>
          <Badge variant='outline'>{event.metadata.rfp_name}</Badge>
        </EventDetailContainer>
      );

    case 'contract_invoice_uploaded':
    case 'appraisal_invoice_uploaded':
      return (
        <EventDetailContainer>
          <Badge variant='outline'>
            {event.metadata.invoice_original_name}
          </Badge>
        </EventDetailContainer>
      );

    case 'order_updates_published':
    case 'order_updates_requested':
    case 'contract_updates_published':
    case 'contract_updates_requested':
    case 'appraisal_updates_published':
    case 'appraisal_updates_requested':
      return (
        <EventDetailContainer>
          <MessageAuthor>{event.metadata.message}</MessageAuthor>
        </EventDetailContainer>
      );

    case 'contract_report_rejected':
    case 'contract_report_accepted':
    case 'contract_report_created':
    case 'appraisal_report_rejected':
    case 'appraisal_report_accepted':
    case 'appraisal_report_created':
      return (
        <EventDetailContainer>
          {event.type === 'appraisal_report_rejected' && (
            <MessageAuthor>{event.metadata.reason}</MessageAuthor>
          )}
          <EventDetailList>
            <Badge variant='outline'>Version {event.metadata.version}</Badge>
            {'report_original_name' in event.metadata && (
              <Badge variant='outline'>
                Informe {event.metadata.report_original_name}
              </Badge>
            )}
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'claim_estimates_updated':
      return (
        <EventDetailContainer>
          <div className='flex flex-row items-center gap-1'>
            <EventDetailList>
              <Badge variant='outline'>
                {rootT(
                  `claim.estimates.${event.metadata.claim_estimates_property}`,
                )}
              </Badge>
            </EventDetailList>
            {event.metadata.old_value && (
              <>
                <Badge variant='outline'>{event.metadata.old_value}</Badge>
                <IconArrowRight className='w-4 h-4 text-muted-foreground mx-2' />
              </>
            )}
            <Badge variant='outline'>{event.metadata.new_value}</Badge>
          </div>
        </EventDetailContainer>
      );

    case 'offer_agreement_expired':
    case 'offer_agreement_rejected':
    case 'offer_agreement_completed':
    case 'offer_agreement_cancelled':
      return (
        <EventDetailContainer className='space-y-3'>
          {event.type === 'offer_agreement_cancelled' && (
            <MessageAuthor>{event.metadata.cancelled_reason}</MessageAuthor>
          )}
          {event.type === 'offer_agreement_rejected' && (
            <MessageAuthor>{event.metadata.rejected_reason}</MessageAuthor>
          )}
          <EventDetailList>
            <Badge variant={'outline'}>
              {event.metadata.offer_agreement_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'file_request_uploaded':
      return (
        <EventDetailContainer>
          <FileList className='w-min flex flex-row items-center gap-2'>
            {event.details.map((detail) => {
              if (detail.object_type !== 'file') return;

              return (
                <a
                  className='hover:underline'
                  target='_blank'
                  href={detail.file.url}
                >
                  <FileListItem className='h-6 mr-2'>
                    <FileListLabel
                      mimetype={detail.file.mimetype!}
                      className='w-72 [&>svg]:w-4 [&>svg]:h-4'
                    >
                      {detail.file.stored_name}
                    </FileListLabel>
                  </FileListItem>
                </a>
              );
            })}
          </FileList>
        </EventDetailContainer>
      );

    case 'claim_file_uploaded_by_insurer':
    case 'claim_file_uploaded': {
      if (event.metadata?.file_tags?.length === undefined) return null;
      return (
        <EventDetailContainer>
          <EventDetailList>
            {(event.metadata?.file_tags || []).map((file_tag) => {
              return (
                <FileListItem className='h-[1.625rem] mr-2 p-1 border border-stone-300 w-fit'>
                  <FileListLabel
                    mimetype={event.metadata?.mimetype || ''}
                    className='[&>svg]:w-4 [&>svg]:h-4'
                    textClassName='text-stone-700 font-medium text-base_brand'
                  >
                    {file_tag}
                  </FileListLabel>
                </FileListItem>
              );
            })}
          </EventDetailList>
        </EventDetailContainer>
      );
    }

    case 'file_requested': {
      const [fileRequest] = event.details;

      const placeholders =
        fileRequest?.object_type === 'file_request' &&
        !!fileRequest.file_request
          ? fileRequest.file_request?.placeholders?.map((placeholder) => ({
              tagName: placeholder.tag.name,
            }))
          : event.metadata.placeholders?.map((placeholder) => ({
              tagName: placeholder.tag,
            }));

      return (
        <EventDetailContainer>
          <EventDetailList>
            {(placeholders || []).map((placeholder) => {
              return (
                <Badge
                  variant={'outline'}
                  key={placeholder.tagName}
                  className='border-primary shadow-none text-base_brand'
                >
                  {placeholder.tagName}
                </Badge>
              );
            })}
          </EventDetailList>
        </EventDetailContainer>
      );
    }

    case 'claim_form_step_view':
      return (
        <EventDetailContainer>
          <Badge variant={'outline'}>{t(event.metadata.step)}</Badge>
        </EventDetailContainer>
      );

    case 'sinister_complaint_chat_message_send':
    case 'sinister_claim_chat_message_send':
      return (
        <EventDetailContainer>
          <p className='italic text-stone-700 text-base_brand'>
            {event.metadata.message}
          </p>
        </EventDetailContainer>
      );

    case 'claim_form_step_completed':
      return (
        <EventDetailContainer>
          <Badge variant={'outline'}>{t(event.metadata.step)}</Badge>
        </EventDetailContainer>
      );

    case 'claim_updated': {
      const diffs = computeEventDiffs(event.metadata.diff);

      return (
        <EventDetailContainer>
          <ul className='font-normal text-stone-500 list-none'>
            {diffs.map((diff, index) => (
              <li key={index}>{diff}</li>
            ))}
          </ul>
        </EventDetailContainer>
      );
    }

    case 'claim_responsibility_returned':
      return (
        <EventDetailContainer>
          <p>
            y dejo el siguiente motivo:
            <span className='text-base_brand text-muted-foreground italic mt-2'>
              "{event.metadata.reason}"
            </span>
          </p>
        </EventDetailContainer>
      );

    case 'claim_derived':
      return (
        <EventDetailContainer>
          <p>
            y {isTriggeredByAuthUser ? 'Dejaste' : 'dejo'} el siguiente motivo:
            <span className='text-base_brand text-muted-foreground italic mt-2'>
              "{event.metadata.reason}"
            </span>
          </p>
        </EventDetailContainer>
      );

    case 'offer_canceled':
      return (
        <EventDetailContainer>
          <p>
            por el motivo:{' '}
            <span className='italic break-words max-w-full'>
              "{event.metadata.reason}"
            </span>
          </p>
        </EventDetailContainer>
      );

    default:
      return null;
  }
};

const Content = ({ children }: { children: React.ReactNode }) => {
  const { index } = useActivityContext();

  return (
    <div
      className={cn('text-base_brand flex flex-col', {
        'mt-5': index !== 0,
      })}
    >
      {children}
    </div>
  );
};

const TriggeredByAvatar = ({
  triggeredBy,
  size = 'sm',
}: {
  triggeredBy: EventComplete['triggered_by'];
  size?: 'xs' | 'sm' | 'md' | 'lg';
}) => {
  return (
    <Avatar size={size}>
      <AvatarImage
        src={triggeredBy?.profile_photo || '/images/small-user-placeholder.png'}
      />
      <AvatarFallback className='text-xs'>
        {`${triggeredBy?.first_name?.charAt(0)}${triggeredBy?.last_name?.charAt(
          0,
        )}`}
      </AvatarFallback>
    </Avatar>
  );
};

const Marker = () => {
  const { index, event } = useActivityContext();

  const Icon = iconsByEvents[event.type];

  return (
    <div
      className={cn('absolute -left-3', {
        'top-5': index !== 0,
      })}
    >
      <div className='rounded-full p-1 bg-card shadow'>
        {Icon && <Icon className='w-4 h-4 text-muted-foreground' />}
      </div>
    </div>
  );
};

const Header = ({
  children,
  className,
  eventType,
}: {
  children: React.ReactNode;
  className?: string;
  eventType?: EventComplete['type'];
}) => {
  return (
    <div
      className={cn(
        'flex flex-row items-center space-x-2 justify-between py-2 pl-4 pr-2 rounded-xl',
        className,
        {
          'bg-stone-100': eventType === 'claim_comment_created',
        },
      )}
    >
      {children}
    </div>
  );
};

const findEventDetailObject = <T extends EventDetailObjects>(
  details: EventDetailUnion[],
  object: T,
) =>
  details.find(
    (detail): detail is Extract<EventDetailUnion, { object_type: T }> =>
      detail.object_type === object,
  ) as Extract<EventDetailUnion, { object_type: T }>;

const computeEventDiffs = (
  diff: Array<{
    type: string;
    path: Array<string | number>;
    oldValue: any;
    value: any;
  }>,
) => {
  const t = useTranslations();

  const changes = diff
    .filter((diff) => {
      return [diff.oldValue, diff.value].every((val) => {
        if (val === null) return true;
        return typeof val !== 'object' && !Array.isArray(val);
      });
    })
    .map((diff) => {
      if (diff.type !== 'CHANGE') return null;

      if (diff.oldValue === diff.value) return null;

      const segments = diff.path.filter(
        (path): path is string => typeof path === 'string',
      );

      const label = segments
        .map((segment, index) => {
          const prevSegments = segments.slice(0, index);

          return t(
            `claim.attributes${
              prevSegments.length ? `.${prevSegments.join('.')}` : ''
            }.${segment}.label`,
          );
        })
        .join('/');

      const path = `claim.attributes.${diff.path
        .filter((path): path is string => typeof path === 'string')
        .join('.')}`;

      return (
        <span>
          {label}
          {diff.oldValue && (
            <>
              {' de '}
              <strong className='font-semibold'>
                {parseSuperType(diff.oldValue, () =>
                  t(`${path}.value.${diff.oldValue}`, {}, diff.oldValue || ''),
                )}
              </strong>
            </>
          )}
          {' a '}
          <strong className='font-semibold'>
            {parseSuperType(diff.value, () =>
              t(`${path}.value.${diff.value}`, {}, diff.value || ''),
            )}
          </strong>
        </span>
      );
    })
    .filter(Boolean); // Remove null values

  return changes;
};

const hasEventDetails = (event: EventComplete): boolean => {
  // First check if the event type should have details
  const shouldHaveDetails = (() => {
    switch (event.type) {
      case 'alert_sla_objective_progress':
      case 'alert_sla_total_progress':
      case 'complaint_estimates_updated':
      case 'external_complaint_rejected':
      case 'external_complaint_approved':
      case 'external_complaint_authorized':
      case 'external_complaint_created':
      case 'rfp_expired':
      case 'contract_invoice_uploaded':
      case 'appraisal_invoice_uploaded':
      case 'order_updates_published':
      case 'order_updates_requested':
      case 'contract_updates_published':
      case 'contract_updates_requested':
      case 'appraisal_updates_published':
      case 'appraisal_updates_requested':
      case 'contract_report_rejected':
      case 'contract_report_accepted':
      case 'contract_report_created':
      case 'appraisal_report_rejected':
      case 'appraisal_report_accepted':
      case 'appraisal_report_created':
      case 'claim_estimates_updated':
      case 'offer_agreement_expired':
      case 'offer_agreement_rejected':
      case 'offer_agreement_completed':
      case 'offer_agreement_cancelled':
      case 'file_request_uploaded':
      case 'claim_file_uploaded_by_insurer':
      case 'claim_file_uploaded':
      case 'file_requested':
      case 'claim_form_step_view':
      case 'sinister_complaint_chat_message_send':
      case 'sinister_claim_chat_message_send':
      case 'claim_form_step_completed':
      case 'claim_updated':
      case 'claim_responsibility_returned':
      case 'claim_derived':
      case 'offer_canceled':
        return true;
      default:
        return false;
    }
  })();

  // If the event type shouldn't have details, return false
  if (!shouldHaveDetails) {
    return false;
  }

  // Then verify if the event actually has meaningful details
  switch (event.type) {
    case 'claim_file_uploaded':
    case 'claim_file_uploaded_by_insurer':
      return !!event.metadata?.file_tags?.length;

    case 'file_requested':
      return !!event.details.length || !!event.metadata?.placeholders?.length;

    case 'claim_updated':
      return !!event.metadata?.diff?.length;

    // Add other specific cases where we need to verify the actual content

    default:
      // For other types that should have details, verify metadata exists
      return !!event.metadata && Object.keys(event.metadata).length > 0;
  }
};

const EventAction = ({
  authUser,
  path,
}: {
  authUser: AuthInfoResponseBody;
  path?: string;
}) => {
  const { event } = useActivityContext();
  const t = useTranslations();

  const isTriggeredByAuthUser = event.triggered_by_id === authUser?.id;

  switch (event.type) {
    case 'complaint_updated':
      return (
        <>
          {isTriggeredByAuthUser ? 'Actualizaste' : 'actualizó'} datos de la
          denuncia
        </>
      );

    case 'alert_sla_total_progress':
      return (
        <>
          notifica que el SLA {event.metadata.sla_name} esta al{' '}
          {event.metadata.sla_total_progress}% de su progreso
        </>
      );

    case 'alert_sla_objective_progress':
      return (
        <>
          notifica que el{' '}
          {t(`sla.types.${event.metadata.sla_objective_reference_type}`)}{' '}
          {event.metadata.sla_objective_reference_name} del SLA{' '}
          {event.metadata.sla_name} esta al{' '}
          {event.metadata.sla_objective_progress}% de su progreso
        </>
      );

    case 'complaint_responsible_assigned': {
      return (
        <>
          {isTriggeredByAuthUser ? 'Asignaste' : 'asignó'} a{' '}
          {event.metadata.responsible_user_email} como responsable
        </>
      );
    }

    case 'complaint_collaborator_assigned': {
      return (
        <>
          {isTriggeredByAuthUser ? 'Agregaste' : 'agregó'} a{' '}
          {event.metadata.collaborator_user_email} como colaborador
        </>
      );
    }

    case 'complaint_collaborator_unassigned': {
      return (
        <>
          {isTriggeredByAuthUser ? 'Removiste' : 'removió'} a{' '}
          {event.metadata.collaborator_user_email} como colaborador
        </>
      );
    }

    case 'complaint_collaborator_unassigned':
      return (
        <>{isTriggeredByAuthUser ? 'Removiste' : 'removió'} un colaborador</>
      );

    case 'complaint_estimates_updated':
      return (
        <>
          {isTriggeredByAuthUser ? 'Actualizaste' : 'actualizó'} las{' '}
          <EventActionLink linkText='estimaciones' url={`${path}/estimates`} />
        </>
      );

    case 'external_complaint_rejected':
      return (
        <>
          {isTriggeredByAuthUser ? 'Rechazaste' : 'rechazó'} la denuncia en el
          sistema tercero
        </>
      );

    case 'complaint_rejected':
      return (
        <>{isTriggeredByAuthUser ? 'Rechazaste' : 'rechazó'} la denuncia</>
      );

    case 'external_complaint_approved':
      return (
        <>
          {isTriggeredByAuthUser ? 'Aprobaste' : 'aprobó'} la denuncia en el
          sistema tercero
        </>
      );

    case 'complaint_approved':
      return <>{isTriggeredByAuthUser ? 'Aprobaste' : 'aprobó'} la denuncia</>;

    case 'external_complaint_authorized':
      return (
        <>
          {isTriggeredByAuthUser ? 'Autorizaste' : 'autorizó'} la denuncia en el
          sistema tercero
        </>
      );

    case 'complaint_authorized':
      return (
        <>{isTriggeredByAuthUser ? 'Autorizaste' : 'autorizó'} la denuncia</>
      );

    case 'external_complaint_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Creaste' : 'creó'} la denuncia en el sistema
          tercero
        </>
      );

    case 'rfp_expired':
      return (
        <>{isTriggeredByAuthUser ? 'Expiraste' : 'expiró'} la licitación</>
      );

    case 'claim_updated': {
      if (isTriggeredByAuthUser) {
        return (
          <div className='flex flex-col items-start w-full'>
            <p>
              Actualizaste el{' '}
              <EventActionLink linkText='reclamo' url={`${path}`} />
              {': '}
            </p>
          </div>
        );
      }
      return (
        <div className='flex flex-col items-start space-x-2 w-full'>
          <p>
            actualizó el <EventActionLink linkText='reclamo' url={`${path}`} />
            {': '}
          </p>
        </div>
      );
    }

    case 'claim_estimates_updated':
      return (
        <>
          {isTriggeredByAuthUser ? 'Actualizaste' : 'actualizó'} un dato de la{' '}
          <EventActionLink linkText='estimación' url={`${path}/estimates`} />
        </>
      );

    case 'pending_file_request_upload':
      return (
        <>
          {isTriggeredByAuthUser ? 'Notificaste' : 'notificó'} al reclamante
          usuario sobre la documentación pendiente
        </>
      );

    case 'pending_offer_response_internal':
      return (
        <>
          {isTriggeredByAuthUser ? 'Notificaste' : 'notificó'} al tramitador
          responsable sobre el ofrecimiento pendiente sin responder
        </>
      );

    case 'pending_offer_response':
      return (
        <>
          {isTriggeredByAuthUser ? 'Notificaste' : 'notificó'} al reclamante
          usuario sobre el ofrecimiento pendiente sin responder
        </>
      );

    case 'pending_offer_agreement_response':
      return (
        <>
          {isTriggeredByAuthUser ? 'Notificaste' : 'notificó'} al reclamante
          usuario sobre la conformidad sin completar
        </>
      );

    case 'pending_offer_agreement_response_internal':
      return (
        <>
          {isTriggeredByAuthUser ? 'Notificaste' : 'notificó'} al tramitador
          responsable sobre la conformidad sin completar
        </>
      );

    case 'claim_documentation_requested':
      return (
        <>{isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} documentación</>
      );

    case 'claim_ownership_assigned':
      return (
        <>
          {isTriggeredByAuthUser ? 'Asignaste' : 'asignó'} como reclamante
          usuario a: {event.metadata.destination_user_name}
        </>
      );

    case 'claim_ownership_transfer_rejected':
      return (
        <>
          {isTriggeredByAuthUser ? 'Rechazaste' : 'rechazó'} la solicitud de
          transferencia de propiedad del reclamo
        </>
      );

    case 'claim_ownership_transfer_accepted':
      return (
        <>
          {isTriggeredByAuthUser ? 'Aceptaste' : 'aceptó'} la solicitud de
          transferencia de propiedad del reclamo
        </>
      );

    case 'claim_ownership_transfer_requested':
      return (
        <>
          {isTriggeredByAuthUser ? 'Enviaste' : 'envió'} una solicitud de
          transferencia de propiedad del reclamo al usuario: "
          {event.metadata.destination_user_email}"
        </>
      );

    case 'contract_report_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Agregaste' : 'agregó'} un reporte en el
          contrato: "{event.metadata.contract_name}"
        </>
      );

    case 'order_report_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Agregaste' : 'agregó'} un reporte en la
          orden: "{event.metadata.order_name}"
        </>
      );

    case 'contract_invoice_uploaded':
      return (
        <>
          {isTriggeredByAuthUser ? 'Subiste' : 'subió'} una factura en el
          contrato: "{event.metadata.contract_name}"
        </>
      );

    case 'order_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Creaste' : 'creó'} la orden: "
          {event.metadata.order_name}"
        </>
      );

    case 'contract_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Creaste' : 'creó'} el contrato: "
          {event.metadata.contract_name}"
        </>
      );

    case 'rfp_bid_submitted':
      return (
        <>
          {isTriggeredByAuthUser ? 'Enviaste' : 'envió'} su oferta en la
          licitación
        </>
      );

    case 'rfp_bid_winner_selected':
      return (
        <>
          {isTriggeredByAuthUser ? 'Seleccionaste' : 'seleccionó'} al proveedor
          ganador: "{event.metadata.vendor_contact_name}"
        </>
      );

    case 'rfp_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Creaste' : 'creó'} la licitación:{' '}
          <EventActionLink
            linkText={event.metadata.rfp_name}
            url={`${path}/rfps`}
          />
        </>
      );

    case 'rfp_canceled':
      return (
        <>
          {isTriggeredByAuthUser ? 'Cancelaste' : 'canceló'} la licitación:{' '}
          <EventActionLink
            linkText={event.metadata.canceled_reason}
            url={`${path}/rfps`}
          />
        </>
      );

    case 'order_updates_requested':
      return (
        <>
          {isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} novedades de la
          orden
        </>
      );

    case 'contract_updates_requested':
      return (
        <>
          {isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} novedades del
          contrato
        </>
      );

    case 'order_updates_requested':
      return (
        <>
          {isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} novedades de la
          orden
        </>
      );

    case 'contract_updates_published':
      return (
        <>
          {isTriggeredByAuthUser ? 'Publicaste' : 'publicó'} novedades del
          contrato
        </>
      );

    case 'order_updates_published':
      return (
        <>
          {isTriggeredByAuthUser ? 'Publicaste' : 'publicó'} novedades de la
          orden
        </>
      );

    case 'contract_report_rejected':
      return (
        <>
          {isTriggeredByAuthUser ? 'Rechazaste' : 'rechazó'} un reporte de
          contrato
        </>
      );

    case 'order_report_rejected':
      return (
        <>
          {isTriggeredByAuthUser ? 'Rechazaste' : 'rechazó'} un reporte de orden
        </>
      );

    case 'contract_report_accepted':
      return (
        <>
          {isTriggeredByAuthUser ? 'Aceptaste' : 'aceptó'} un reporte de
          contrato
        </>
      );

    case 'order_report_accepted':
      return (
        <>
          {isTriggeredByAuthUser ? 'Aceptaste' : 'aceptó'} un reporte de orden
        </>
      );

    case 'appraisal_invoice_uploaded':
      return (
        <>
          {isTriggeredByAuthUser ? 'Subiste' : 'subió'} una factura de la
          evaluación
        </>
      );

    case 'appraisal_updates_requested':
      return (
        <>
          {isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} novedades de la
          evaluación
        </>
      );

    case 'appraisal_updates_published':
      return (
        <>
          {isTriggeredByAuthUser ? 'Publicaste' : 'publicó'} novedades sobre la
          evaluación
        </>
      );

    case 'appraisal_report_rejected':
      return (
        <>
          {isTriggeredByAuthUser ? 'Rechazaste' : 'rechazó'} un informe de
          evaluación
        </>
      );

    case 'appraisal_report_created':
      return (
        <>
          {isTriggeredByAuthUser ? 'Agregaste' : 'agregó'} un informe de
          evaluación
        </>
      );

    case 'appraisal_report_accepted':
      return (
        <>
          {isTriggeredByAuthUser ? 'Aceptaste' : 'aceptó'} un informe de
          evaluación
        </>
      );

    case 'offer_agreement_completed': {
      if (authUser?.type === 'insurer') {
        return (
          <>
            {isTriggeredByAuthUser ? 'Enviaste' : 'envió'} la conformidad via
            Autosign
          </>
        );
      }
      return (
        <>
          {isTriggeredByAuthUser ? 'Enviaste' : 'envió'} la conformidad via
          Autosign
        </>
      );
    }

    case 'offer_agreement_expired':
      return <>una conformidad expiró</>;

    case 'offer_agreement_rejected':
      return <>una conformidad fue rechazada por el tercero</>;

    case 'offer_agreement_cancelled':
      return <>una conformidad fue cancelada por la empresa</>;

    case 'offer_expired':
      return <>expiro un ofrecimiento</>;

    case 'offer_rejected': {
      if (authUser?.type === 'insurer') {
        return (
          <div className='flex flex-wrap items-center gap-1'>
            <p>
              rechazó el ofrecimiento
              <EventActionLink
                linkText={event.metadata.offer_name}
                url={`${path}/offers`}
              />
              por el motivo:{' '}
              <span className='italic break-words max-w-full'>
                "{event.metadata.reason}"
              </span>
            </p>
            {event.metadata.third_party_user_requested_amount && (
              <>
                y el monto pretendido es
                <span className='italic break-words'>
                  "{event.metadata.third_party_user_requested_amount}"
                </span>
              </>
            )}
          </div>
        );
      }
      if (isTriggeredByAuthUser) {
        return (
          <div className='flex flex-wrap items-center gap-1'>
            <p>
              Rechazaste el
              <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
              por el motivo:
              <span className='italic break-words max-w-full'>
                "{event.metadata.reason}"
              </span>
            </p>
            {event.metadata.third_party_user_requested_amount && (
              <>
                y el monto pretendido es
                <span className='italic break-words'>
                  "{event.metadata.third_party_user_requested_amount}"
                </span>
              </>
            )}
          </div>
        );
      }
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            Rechazó el
            <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
            por el motivo:{' '}
            <span className='italic break-words max-w-full'>
              "{event.metadata.reason}"
            </span>
          </p>
          {event.metadata.third_party_user_requested_amount && (
            <>
              y el monto pretendido es
              <span className='italic break-words'>
                "{event.metadata.third_party_user_requested_amount}"
              </span>
            </>
          )}
        </div>
      );
    }

    case 'offer_canceled': {
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            {isTriggeredByAuthUser ? 'Anulaste' : 'anuló'} el
            <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
          </p>
        </div>
      );
    }

    case 'offer_created': {
      if (authUser?.type === 'insurer') {
        if (isTriggeredByAuthUser) {
          return (
            <div className='flex flex-wrap items-center gap-1'>
              <p>
                Enviaste el
                <EventActionLink
                  linkText='ofrecimiento'
                  url={`${path}/offers`}
                />
                {event.metadata.offer_name}
              </p>
            </div>
          );
        }
        return (
          <div className='flex flex-wrap items-center gap-1'>
            <p>
              envió el
              <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
              {event.metadata.offer_name}
            </p>
          </div>
        );
      }
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            te envió el
            <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
            {event.metadata.offer_name}
          </p>
        </div>
      );
    }

    case 'offer_accepted': {
      if (isTriggeredByAuthUser) {
        return (
          <div className='flex flex-wrap items-center gap-1'>
            <p>
              Aceptaste el
              <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
              {event.metadata.offer_name}
            </p>
          </div>
        );
      }
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            aceptó el
            <EventActionLink linkText='ofrecimiento' url={`${path}/offers`} />
            {event.metadata.offer_name}
          </p>
        </div>
      );
    }

    case 'fraud_news_answered':
      return (
        <>
          {isTriggeredByAuthUser ? 'Agregaste' : 'agregó'} novedades a la
          investigación
        </>
      );

    case 'fraud_news_requested':
      return (
        <>
          {isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} novedades de la
          investigación
        </>
      );

    case 'claim_form_step_completed':
      return (
        <>
          {isTriggeredByAuthUser ? 'Completaste' : 'completó'} un paso del
          formulario
        </>
      );

    case 'claim_form_step_view':
      return (
        <>
          {isTriggeredByAuthUser ? 'Visitaste' : 'visitó'} un paso del
          formulario
        </>
      );

    case 'sinister_complaint_chat_message_send':
    case 'sinister_claim_chat_message_send': {
      if (isTriggeredByAuthUser) {
        return (
          <>
            Enviaste un{' '}
            <EventActionLink linkText='mensaje' url={`${path}/messages`} />
          </>
        );
      }
      return (
        <>
          envió un{' '}
          <EventActionLink linkText='mensaje' url={`${path}/messages`} />
        </>
      );
    }

    case 'file_request_uploaded':
      return (
        <>
          {isTriggeredByAuthUser ? 'Subiste' : 'subió'}{' '}
          <EventActionLink linkText='archivos' url={`${path}/documents`} />
        </>
      );

    case 'complaint_created':
      return <>creó la denuncia</>;

    case 'claim_created':
      return <>{isTriggeredByAuthUser ? 'Creaste' : 'creó'} el reclamo</>;

    case 'file_requested': {
      const detail = findEventDetailObject(event.details, 'file_request');

      if (detail) {
        if (authUser?.type !== 'insurer') {
          return (
            <>
              te solicitó los siguientes{' '}
              <EventActionLink linkText='archivos' url={`${path}/documents`} />
            </>
          );
        }
        return (
          <>
            le solicitó al reclamante los siguientes{' '}
            <EventActionLink linkText='archivos' url={`${path}/documents`} />
          </>
        );
      }
      return (
        <>
          {isTriggeredByAuthUser ? 'Solicitaste' : 'solicitó'} los siguientes{' '}
          <EventActionLink linkText='archivos' url={`${path}/documents`} />
        </>
      );
    }

    case 'order_invoice_uploaded':
      return (
        <>
          {isTriggeredByAuthUser ? 'Subiste' : 'subió'} una factura de la orden
        </>
      );

    case 'claim_responsibility_returned': {
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            retornó el reclamo a{event.metadata.responsible_user_first_name}{' '}
            {event.metadata.responsible_user_last_name} (
            {event.metadata.responsible_user_email})
          </p>
        </div>
      );
    }

    case 'pending_complaint_created':
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            {isTriggeredByAuthUser ? 'Creaste' : 'creó'} una denuncia de oficio
            vinculando los reclamos:
          </p>
          <p>
            {event.metadata.claims
              .map((claim) => `${claim.ref_id || claim.id}`)
              .join(', ')}
          </p>
        </div>
      );

    case 'external_complaint_selected':
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            {isTriggeredByAuthUser ? 'Vinculaste' : 'vinculó'} los reclamos:
            {event.metadata.claims
              .map((claim) => `${claim.ref_id || claim.id}`)
              .join(', ')}
          </p>
          <p>a la denuncia {event.metadata.external_complaint_external_id}</p>
        </div>
      );

    case 'claim_derived': {
      return (
        <div className='flex flex-wrap items-center gap-1'>
          <p>
            {isTriggeredByAuthUser ? 'Derivaste' : 'derivó'} el reclamo a
            {event.metadata.responsible_user_first_name}{' '}
            {event.metadata.responsible_user_last_name} (
            {event.metadata.responsible_user_email})
          </p>
        </div>
      );
    }

    case 'claim_responsible_assigned': {
      const detail = findEventDetailObject(event.details, 'user');

      const user = detail?.user
        ? {
            name: `${detail.user.first_name} ${detail.user.last_name}`,
          }
        : {
            name: event.metadata.responsible_user_first_name
              ? `${event.metadata.responsible_user_first_name} ${event.metadata.responsible_user_last_name}`
              : event.metadata.responsible_user_email,
          };

      return (
        <>
          {isTriggeredByAuthUser ? 'Asignaste' : 'asignó'} a {user.name} como
          responsable
        </>
      );
    }

    case 'claim_collaborator_assigned': {
      const { user } = findEventDetailObject(event.details, 'user');

      if (!user) {
        return;
      }

      return (
        <>
          {isTriggeredByAuthUser ? 'Agregaste' : 'agregó'} a {user.first_name}{' '}
          {user.last_name} como colaborador
        </>
      );
    }

    case 'claim_collaborator_unassigned': {
      const { user } = findEventDetailObject(event.details, 'user');

      if (!user) {
        return;
      }

      return (
        <>
          {isTriggeredByAuthUser ? 'Removiste' : 'removió'} a {user.first_name}{' '}
          {user.last_name} como colaborador
        </>
      );
    }

    case 'complaint_sinister_cause_updated': {
      return (
        <p>
          {isTriggeredByAuthUser ? 'Actualizaste' : 'actualizó'} la causa del
          siniestro de {event.metadata.previous_sinister_cause_name || ''}a{' '}
          {event.metadata.new_sinister_cause_name}
        </p>
      );
    }

    case 'complaint_status_updated':
    case 'claim_status_updated': {
      return (
        <p>
          {isTriggeredByAuthUser ? 'Actualizaste' : 'actualizó'} el estado de{' '}
          {event.metadata.previous_status_name} a{' '}
          {event.metadata.new_status_name}
        </p>
      );
    }

    case 'claim_file_uploaded_by_insurer':
    case 'claim_file_uploaded': {
      if (event.metadata?.file_tags?.length) {
        return (
          <p>
            {isTriggeredByAuthUser ? 'Adjuntaste' : 'adjuntó'} los siguientes
            <EventActionLink linkText='archivos' url={`${path}/documents`} />
          </p>
        );
      }
      return (
        <p>
          {isTriggeredByAuthUser ? 'Adjuntaste' : 'adjuntó'}
          <EventActionLink linkText='archivos' url={`${path}/documents`} />
        </p>
      );
    }

    default:
      return event.type;
  }
};

const EventActionLink = ({
  linkText,
  url,
}: {
  linkText: string;
  url: string;
}) => {
  return (
    <Link href={url} className='text-base_brand underline underline-offset-4'>
      {' '}
      {linkText}{' '}
    </Link>
  );
};

const Action = ({
  triggeredBy,
  path,
  className,
}: {
  triggeredBy: EventComplete['triggered_by'];
  path?: string;
  className?: string;
}) => {
  const auth = useSharedAuth();
  const { event } = useActivityContext();

  const isTriggeredByAuthUser = triggeredBy?.id === auth?.user?.id;

  return (
    <div
      className={cn(
        'font-medium text-base_brand text-stone-700 flex flex-row items-center',
        className,
      )}
    >
      <div className='flex-shrink-0 ml-0 mr-1'>
        {triggeredBy && isTriggeredByAuthUser
          ? ``
          : `${triggeredBy?.first_name || ''}${
              triggeredBy?.last_name ? ` ${triggeredBy?.last_name || ''}` : ''
            }`}
      </div>
      <div className='flex-shrink-0 gap-1'>
        <EventAction authUser={auth?.user} path={path} />
      </div>
    </div>
  );
};

const Item = ({
  children,
  eventsCount,
  index,
  event,
}: {
  children: React.ReactNode;
  index: number;
  eventsCount: number;
  event: EventComplete;
}) => {
  return (
    <ActivityContext.Provider value={{ event, eventsCount, index }}>
      <div className={cn('relative pr-2')}>{children}</div>
    </ActivityContext.Provider>
  );
};

const TimeAgo = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'text-xs flex flex-col justify-end !mt-[2.5px] text-stone-500',
        className,
      )}
    >
      {children}
    </div>
  );
};

const TriggeredInfo = ({
  event,
  path,
}: {
  event: EventComplete;
  path?: string;
}) => {
  const hasDetails = hasEventDetails(event);

  return (
    <div className='flex flex-row items-start space-x-2 w-5/6 h-fit'>
      <Activity.TriggeredByAvatar triggeredBy={event.triggered_by} />
      {event.type !== 'claim_comment_created' ? (
        <div className='flex flex-col justify-center'>
          <Activity.Action
            triggeredBy={event.triggered_by}
            path={path}
            className={cn({
              'h-7': !hasDetails,
            })}
          />
          <Activity.Detail />
        </div>
      ) : (
        <Comment commentContent={event.metadata!.comment_content!} />
      )}
    </div>
  );
};

const Comment = ({ commentContent }: { commentContent: string }) => {
  return (
    <p className='italic text-stone-700 text-base_brand min-h-7 flex flex-col justify-center'>
      {commentContent}
    </p>
  );
};

export const Activity = ({
  children,
  className,
  userType,
}: {
  children: React.ReactNode;
  className?: string;
  userType?: UserType;
}) => {
  return (
    <div
      className={cn('flex flex-col justify-end', className, {
        'pb-36': userType === 'insurer',
        'pb-10': userType !== 'insurer',
      })}
    >
      {children}
    </div>
  );
};

Activity.Item = Item;
Activity.Content = Content;
Activity.Marker = Marker;
Activity.Header = Header;
Activity.TriggeredByAvatar = TriggeredByAvatar;
Activity.Action = Action;
Activity.TimeAgo = TimeAgo;
Activity.Detail = EventDetail;
Activity.TriggeredInfo = TriggeredInfo;
Activity.Comment = Comment;
